import React, { useCallback } from 'react';
import CookieConsent from 'react-cookie-consent';

import Link from '../Link';

const CookieConsentBar = () => {
  const Button = useCallback(({ children, ...props }) => (
    <button
      {...props}
      type="button"
      data-testid="cookie-consent-button-aceptar"
    >
      {children}
    </button>
  ), []);

  return (
    <CookieConsent
      location="bottom"
      buttonText="Aceptar"
      ButtonComponent={Button}
      buttonClasses="text-black font-bold mr-24 m-1 py-2 px-4 border-0 bg-primaryOfertas"
      disableButtonStyles
      ariaAcceptLabel="Aceptar cookies"
      ariaDeclineLabel="Rechazar cookies"
    >
      Este sitio utiliza cookies para mejorar tu experiencia. Cerrando este
      banner o navegando en esta página aceptas el uso de las cookies. Si deseas
      saber más consulta nuestra
      {' '}
      <Link href="/politica-de-cookies">política de cookies.</Link>
    </CookieConsent>
  );
};

export default CookieConsentBar;
